* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Raleway',sans-serif;
  scroll-behavior: smooth;
  scroll-padding-top: 150px;
}

*:before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

html {
  font-size: 16px;
}

.dnone {
  display: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

ul {
  padding: none;
}

mark {
  background-color: #1BEFA9;
  padding: 10px;
}

.halo_path {
  -webkit-animation: move_halo 2s infinite;
          animation: move_halo 2s infinite;
  animation-direction: alternate-reverse;
}

@-webkit-keyframes move_halo {
  0% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px);
  }
  100% {
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
  }
}

@keyframes move_halo {
  0% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px);
  }
  100% {
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
  }
}

.headerMenu {
  position: absolute;
  top: 40px;
  width: 100%;
  height: 100px;
  -webkit-transition: background .6s ease;
  transition: background .6s ease;
}

.headerMenu.bg-black {
  -webkit-transition: background .6s ease;
  transition: background .6s ease;
  background-color: #000;
}

.headerMenu .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-inline: 12rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.headerMenu .container .menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 25px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style-type: none;
}

.headerMenu .container .menu ul li a {
  font-size: 1.125rem;
  color: white;
}

.headerMenu .container .mobileMenu {
  display: none;
}

@media only screen and (min-width: 1601px) and (max-width: 1750px) {
  .headerMenu .container {
    padding-inline: 8rem;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .headerMenu .container {
    padding-inline: 8rem;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1439px) {
  .headerMenu .container {
    padding-inline: 8rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .headerMenu .container {
    padding-inline: 5rem;
  }
  .headerMenu .container .menu ul {
    gap: 20px;
  }
  .headerMenu .container .menu ul li a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1023px) {
  .headerMenu {
    position: fixed;
    background-color: transparent;
    top: 0;
    width: 100%;
    z-index: 9999;
    height: 70px;
  }
  .headerMenu .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 2rem;
    align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;
  }
  .headerMenu .container .logo img {
    height: 50px;
    width: 100%;
  }
  .headerMenu .container .menu {
    display: none;
  }
  .headerMenu .container .mobileMenu {
    display: block;
  }
  .headerMenu .container .mobileMenu .mobileMenuIcon .bar1, .headerMenu .container .mobileMenu .mobileMenuIcon .bar2, .headerMenu .container .mobileMenu .mobileMenuIcon .bar3 {
    width: 35px;
    height: 3px;
    background-color: #ffffff;
    margin: 6px 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 22px;
  }
  .headerMenu .container .mobileMenu .mobileMenuIcon .bar2 {
    width: 25px;
    margin: auto 0 auto auto;
  }
  .headerMenu .container .mobileMenu .mobileMenuIcon.active .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 3px);
    transform: rotate(-45deg) translate(-9px, 3px);
  }
  .headerMenu .container .mobileMenu .mobileMenuIcon.active .bar2 {
    opacity: 0;
  }
  .headerMenu .container .mobileMenu .mobileMenuIcon.active .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -5px);
    transform: rotate(45deg) translate(-8px, -5px);
  }
}

.heroSection {
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-x: hidden;
}

.heroSection #fundoAbs {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.heroSection .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-inline: 12rem;
  width: 100%;
}

.heroSection .container .hero {
  width: 40%;
}

.heroSection .container .hero h1 {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1;
  letter-spacing: -0.025em;
  max-width: 80%;
  color: #FFFFFF;
}

.heroSection .container .hero a {
  margin-top: 1.5rem;
  background-color: white;
  border-radius: 14px;
  padding: 0.8rem 2rem;
  font-weight: bold;
  color: #000;
  font-size: 1.125rem;
  -webkit-transition: background .6s ease , color .6s ease, -webkit-transform .6s ease;
  transition: background .6s ease , color .6s ease, -webkit-transform .6s ease;
  transition: background .6s ease , color .6s ease, transform .6s ease;
  transition: background .6s ease , color .6s ease, transform .6s ease , -webkit-transform .6s ease;
}

.heroSection .container .hero a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #41f8bb;
  color: #fff;
}

.heroSection .container .imageContainer {
  width: 60%;
}

.heroSection .container .imageContainer img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 1601px) and (max-width: 1750px) {
  .heroSection {
    min-height: 100vh;
    background: url(../../srcFiles/img/hero/shapeFundo.svg) no-repeat;
    background-position: top;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .heroSection .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 8rem;
    width: 100%;
    margin-top: 6rem;
  }
  .heroSection .container .hero {
    width: 40%;
  }
  .heroSection .container .hero h1 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 1;
    letter-spacing: -0.025em;
    color: #FFFFFF;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 60%;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .heroSection {
    min-height: 100vh;
    background: unset;
    background-position: top;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .heroSection .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 8rem;
    width: 100%;
    margin-top: 4rem;
  }
  .heroSection .container .hero {
    width: 40%;
  }
  .heroSection .container .hero h1 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 1;
    letter-spacing: -0.025em;
    color: #FFFFFF;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 60%;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1440px) {
  .heroSection #fundoAbs {
    position: absolute;
    top: 0;
    width: 120%;
    z-index: -1;
  }
  .heroSection .container {
    padding-inline: 6rem;
    width: 100%;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 40%;
  }
  .heroSection .container .hero h1 {
    font-size: 3.4rem;
  }
  .heroSection .container .imageContainer {
    width: 60%;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .heroSection #fundoAbs {
    position: absolute;
    top: 0;
    width: 110%;
    -o-object-position: top;
       object-position: top;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: -1;
  }
  .heroSection .container {
    padding-inline: 5rem;
    width: 100%;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 40%;
  }
  .heroSection .container .hero h1 {
    font-size: 3rem;
  }
  .heroSection .container .imageContainer {
    width: 60%;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .heroSection {
    background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
    background-position: top;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .heroSection .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-inline: 2rem;
    width: 100%;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 60%;
  }
  .heroSection .container .hero h1 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 1;
    letter-spacing: -0.025em;
    color: #FFFFFF;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 60%;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 669px) and (max-width: 768px) {
  .heroSection {
    background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
    padding-top: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: unset;
    padding-bottom: 4rem;
    background-position: top;
    background-size: cover;
  }
  .heroSection .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-inline: 2rem;
    width: 100%;
    gap: 1rem;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 100%;
  }
  .heroSection .container .hero h1 {
    font-size: 3.6rem;
    line-height: 1.1;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 70%;
    margin-top: 0rem;
    padding-top: 2rem;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 591px) and (max-width: 668px) {
  .heroSection {
    background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
    padding-top: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: unset;
    padding-bottom: 4rem;
    background-position: top;
    background-size: cover;
  }
  .heroSection .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-inline: 2rem;
    width: 100%;
    gap: 1rem;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 100%;
  }
  .heroSection .container .hero h1 {
    font-size: 4rem;
    line-height: 1.1;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 100%;
    margin-top: 0rem;
    padding-top: 2rem;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 501px) and (max-width: 590px) {
  .heroSection {
    background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
    padding-top: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: unset;
    padding-bottom: 4rem;
    background-position: top;
    background-size: cover;
  }
  .heroSection .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-inline: 2rem;
    width: 100%;
    gap: 1rem;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 100%;
  }
  .heroSection .container .hero h1 {
    font-size: 3.6rem;
    line-height: 1.1;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 100%;
    margin-top: 0rem;
    padding-top: 2rem;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 500px) {
  .heroSection {
    background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
    padding-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: unset;
    padding-bottom: 4rem;
    background-position: top;
    background-size: cover;
  }
  .heroSection .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-inline: 2rem;
    width: 100%;
    gap: 1rem;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 100%;
  }
  .heroSection .container .hero h1 {
    font-size: 2.6rem;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 100%;
    margin-top: 0rem;
    padding-top: 2rem;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 359px) {
  .heroSection {
    background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
    padding-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: unset;
    padding-bottom: 4rem;
    background-position: top;
    background-size: cover;
  }
  .heroSection .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-inline: 2rem;
    width: 100%;
    gap: 1rem;
    margin-top: 0rem;
  }
  .heroSection .container .hero {
    width: 100%;
  }
  .heroSection .container .hero h1 {
    font-size: 2.2rem;
  }
  .heroSection .container .hero a {
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 14px;
    padding: 0.8rem 2rem;
    font-weight: bold;
    color: #000;
    font-size: 1.125rem;
  }
  .heroSection .container .imageContainer {
    width: 100%;
    margin-top: 0rem;
    padding-top: 2rem;
  }
  .heroSection .container .imageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.importanciaDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-inline: 12rem;
}

.importanciaDiv .leftText {
  width: 50%;
  max-width: 39%;
}

.importanciaDiv .leftText h1 {
  font-family: 'Raleway',sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 95.9%;
  color: #060F26;
}

.importanciaDiv .leftText p {
  margin-top: 1rem;
  font-family: 'Raleway',sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 162.4%;
  letter-spacing: -0.025em;
  color: #525E7A;
  max-width: 80%;
}

.importanciaDiv .leftText a {
  margin-top: 1rem;
  background: #1BEFA9;
  padding: 0.75rem 2.75rem;
  border-radius: 14px;
  color: #060F26;
  font-weight: bold;
  -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
  transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
  transition: background .6s ease , color .6s ease, transform 1s ease;
  transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
}

.importanciaDiv .leftText a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #41f8bb;
  color: #fff;
}

.importanciaDiv .rightImg {
  width: 50%;
  text-align: center;
}

.importanciaDiv .rightImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 700px;
  max-width: 700px;
}

@media only screen and (min-width: 1601px) and (max-width: 1750px) {
  .importanciaDiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-inline: 8rem;
  }
  .importanciaDiv .leftText {
    width: 50%;
    max-width: 40%;
  }
  .importanciaDiv .leftText h1 {
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 95.9%;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 50%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .importanciaDiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-inline: 8rem;
  }
  .importanciaDiv .leftText {
    width: 50%;
    max-width: 40%;
  }
  .importanciaDiv .leftText h1 {
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 95.9%;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 50%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .importanciaDiv {
    padding-inline: 5rem;
  }
  .importanciaDiv .leftText {
    width: 50%;
    max-width: 40%;
  }
  .importanciaDiv .leftText h1 {
    font-size: 2.25rem;
  }
  .importanciaDiv .rightImg {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .importanciaDiv {
    padding-inline: 5rem;
  }
  .importanciaDiv .leftText {
    width: 50%;
    max-width: 40%;
  }
  .importanciaDiv .leftText h1 {
    font-size: 2.25rem;
  }
  .importanciaDiv .rightImg {
    width: 50%;
  }
}

@media only screen and (min-width: 669px) and (max-width: 1024px) {
  .importanciaDiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-inline: 2rem;
  }
  .importanciaDiv .leftText {
    width: 50%;
    max-width: 50%;
  }
  .importanciaDiv .leftText h1 {
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 95.9%;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 50%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 669px) and (max-width: 768px) {
  .importanciaDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
  }
  .importanciaDiv .leftText {
    width: 100%;
    max-width: unset;
  }
  .importanciaDiv .leftText h1 {
    font-size: 3em;
    line-height: 1;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 70%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width: 501px) and (max-width: 668px) {
  .importanciaDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
  }
  .importanciaDiv .leftText {
    width: 100%;
    max-width: unset;
  }
  .importanciaDiv .leftText h1 {
    font-size: 2.5em;
    line-height: 1;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 70%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width: 360px) and (max-width: 500px) {
  .importanciaDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
  }
  .importanciaDiv .leftText {
    width: 100%;
    max-width: unset;
  }
  .importanciaDiv .leftText h1 {
    font-size: 2.125rem;
    line-height: 1;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 100%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width: 0px) and (max-width: 359px) {
  .importanciaDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
  }
  .importanciaDiv .leftText {
    width: 100%;
    max-width: unset;
  }
  .importanciaDiv .leftText h1 {
    font-size: 2rem;
    line-height: 1;
    color: #060F26;
  }
  .importanciaDiv .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    color: #525E7A;
  }
  .importanciaDiv .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .importanciaDiv .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .importanciaDiv .rightImg {
    width: 100%;
  }
  .importanciaDiv .rightImg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.avisarPais {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-inline: 12rem;
  margin-top: -4rem;
}

.avisarPais .leftText {
  width: 55%;
}

.avisarPais .leftText h1 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 48px;
  line-height: 95.9%;
  color: #060F26;
}

.avisarPais .leftText p {
  margin-top: 1rem;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 16px;
  line-height: 162.4%;
  letter-spacing: -0.025em;
  color: #525E7A;
  max-width: 60%;
}

.avisarPais .leftText a {
  margin-top: 1rem;
  background: #1BEFA9;
  padding: 0.75rem 2.75rem;
  border-radius: 14px;
  color: #060F26;
}

.avisarPais .rightImg {
  width: 45%;
}

.avisarPais .rightImg img {
  height: 100%;
  width: 100%;
  max-height: 500px;
  max-width: 500px;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .avisarPais {
    padding-inline: 8rem;
    margin-top: 4rem;
  }
  .avisarPais .leftText {
    width: 50%;
  }
  .avisarPais .leftText h1 {
    font-size: 2.6rem;
  }
  .avisarPais .leftText p {
    max-width: 90%;
  }
  .avisarPais .rightImg {
    width: 50%;
  }
  .avisarPais .rightImg img {
    width: 80%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .avisarPais {
    padding-inline: 5rem;
    margin-top: 4rem;
  }
  .avisarPais .leftText {
    width: 50%;
  }
  .avisarPais .leftText h1 {
    font-size: 2.25rem;
  }
  .avisarPais .leftText p {
    max-width: 100%;
  }
  .avisarPais .rightImg {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .avisarPais {
    padding-inline: 5rem;
    margin-top: 4rem;
  }
  .avisarPais .leftText {
    width: 50%;
  }
  .avisarPais .leftText h1 {
    font-size: 2.25rem;
  }
  .avisarPais .leftText p {
    max-width: 100%;
  }
  .avisarPais .rightImg {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .avisarPais {
    padding-inline: 2rem;
    margin-top: 4rem;
    gap: 2rem;
  }
  .avisarPais .leftText {
    width: 50%;
  }
  .avisarPais .leftText h1 {
    font-size: 2.5rem;
  }
  .avisarPais .leftText p {
    max-width: 100%;
  }
  .avisarPais .rightImg {
    width: 50%;
  }
  .avisarPais .rightImg img {
    width: 100%;
    height: 100%;
  }
  .avisarPais .rightImg svg {
    height: 500px !important;
    width: 500px !important;
  }
}

@media only screen and (min-width: 669px) and (max-width: 768px) {
  .avisarPais {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .avisarPais .leftText {
    width: 100%;
  }
  .avisarPais .leftText h1 {
    font-size: 3rem;
  }
  .avisarPais .leftText p {
    max-width: 100%;
  }
  .avisarPais .rightImg {
    width: 70%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .avisarPais .rightImg img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 501px) and (max-width: 668px) {
  .avisarPais {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .avisarPais .leftText {
    width: 100%;
  }
  .avisarPais .leftText h1 {
    font-weight: 400;
    font-size: 2.125rem;
  }
  .avisarPais .leftText p {
    max-width: 100%;
  }
  .avisarPais .rightImg {
    width: 70%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .avisarPais .rightImg img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .avisarPais {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .avisarPais .leftText {
    width: 100%;
  }
  .avisarPais .leftText h1 {
    font-size: 2.1rem;
  }
  .avisarPais .leftText p {
    max-width: 100%;
  }
  .avisarPais .rightImg {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .avisarPais .rightImg img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 80%;
    height: 100%;
  }
}

.formacao {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-inline: 12rem;
  margin-top: 2rem;
}

.formacao .leftText {
  width: 50%;
  max-width: 39%;
}

.formacao .leftText h1 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 48px;
  line-height: 95.9%;
  color: #060F26;
}

.formacao .leftText p {
  margin-top: 1rem;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 1rem;
  line-height: 162.4%;
  letter-spacing: -0.025em;
  color: #525E7A;
  max-width: 85%;
}

.formacao .leftText a {
  margin-top: 1rem;
  background: #1BEFA9;
  padding: 0.75rem 2.75rem;
  border-radius: 14px;
  color: #060F26;
  font-weight: bold;
  -webkit-transition: background .6s ease , color .6s ease, -webkit-transform .6s ease;
  transition: background .6s ease , color .6s ease, -webkit-transform .6s ease;
  transition: background .6s ease , color .6s ease, transform .6s ease;
  transition: background .6s ease , color .6s ease, transform .6s ease , -webkit-transform .6s ease;
}

.formacao .leftText a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #41f8bb;
  color: #fff;
}

.formacao .rightImg {
  width: 50%;
  max-height: 70vh;
  text-align: center;
}

.formacao .rightImg svg {
  height: 100% !important;
  width: 100% !important;
  max-height: 600px;
  max-width: 600px;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .formacao {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-inline: 8rem;
    margin-top: 2rem;
  }
  .formacao .leftText {
    width: 50%;
    max-width: 50%;
  }
  .formacao .leftText h1 {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 48px;
    line-height: 95.9%;
  }
  .formacao .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 16px;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .formacao .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .formacao .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .formacao .rightImg {
    width: 50%;
  }
  .formacao .rightImg svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .formacao {
    padding-inline: 5rem;
    margin-top: 2rem;
  }
  .formacao .leftText {
    width: 50%;
    max-width: 50%;
  }
  .formacao .leftText h1 {
    font-size: 2.25rem;
  }
  .formacao .rightImg {
    width: 50%;
  }
  .formacao .rightImg svg {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .formacao {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-inline: 2rem;
    margin-top: 4rem;
  }
  .formacao .leftText {
    width: 50%;
    max-width: 50%;
  }
  .formacao .leftText h1 {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 95.9%;
  }
  .formacao .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 1rem;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .formacao .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    font-weight: bold;
    -webkit-transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, -webkit-transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease;
    transition: background .6s ease , color .6s ease, transform 1s ease , -webkit-transform 1s ease;
  }
  .formacao .leftText a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #41f8bb;
    color: #fff;
  }
  .formacao .rightImg {
    width: 50%;
    max-height: 100%;
  }
  .formacao .rightImg svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 669px) and (max-width: 768px) {
  .formacao {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
    margin-top: 2rem;
  }
  .formacao .leftText {
    width: 100%;
    max-width: unset;
  }
  .formacao .leftText h1 {
    font-weight: 400;
    font-size: 3rem;
    line-height: 95.9%;
  }
  .formacao .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 16px;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .formacao .rightImg {
    width: 70%;
    max-height: 70vh;
  }
  .formacao .rightImg svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 668px) {
  .formacao {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
    margin-top: 2rem;
  }
  .formacao .leftText {
    width: 100%;
    max-width: unset;
  }
  .formacao .leftText h1 {
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 95.9%;
  }
  .formacao .leftText p {
    margin-top: 1rem;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 16px;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
  }
  .formacao .rightImg {
    width: 70%;
    max-height: 70vh;
  }
  .formacao .rightImg svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .formacao {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-inline: 2rem;
    margin-top: 2rem;
  }
  .formacao .leftText {
    width: 100%;
    max-width: unset;
  }
  .formacao .leftText h1 {
    font-size: 2.1rem;
  }
  .formacao .leftText p {
    max-width: 100%;
  }
  .formacao .rightImg {
    width: 100%;
    max-height: 70vh;
  }
  .formacao .rightImg svg {
    height: 100% !important;
    width: 100% !important;
  }
}

.educando {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-inline: 12rem;
}

.educando .leftText {
  width: 55%;
  margin-top: -8rem;
}

.educando .leftText h1 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 48px;
  line-height: 95.9%;
  color: #060F26;
}

.educando .leftText p {
  margin-top: 1rem;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 16px;
  line-height: 162.4%;
  letter-spacing: -0.025em;
  color: #525E7A;
  max-width: 70%;
}

.educando .leftText a {
  margin-top: 1rem;
  background: #1BEFA9;
  padding: 0.75rem 2.75rem;
  border-radius: 14px;
  color: #060F26;
}

.educando .leftText .newText h1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.educando .rightImg {
  width: 45%;
}

.educando .rightImg img {
  margin-left: -10rem;
}

.educandoNew .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-inline: 12rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.educandoNew .container .leftText {
  width: 50%;
}

.educandoNew .container .leftText h1 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 48px;
  line-height: 95.9%;
  color: #060F26;
}

.educandoNew .container .leftText p {
  margin-top: 1rem;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 16px;
  line-height: 162.4%;
  letter-spacing: -0.025em;
  color: #525E7A;
  max-width: 70%;
}

.educandoNew .container .leftText a {
  margin-top: 1rem;
  background: #1BEFA9;
  padding: 0.75rem 2.75rem;
  border-radius: 14px;
  color: #060F26;
}

.educandoNew .container .rightImg {
  width: 45%;
}

.educandoNew .container .rightImg img {
  width: 100%;
  height: 100%;
}

.educandoNew .barraMark {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.educandoNew .barraMark .heading {
  background: #1BEFA9;
  padding: 2.5rem 0;
  margin-bottom: 2rem;
}

.educandoNew .barraMark .heading h1 {
  text-align: center;
  font-size: 2.25rem;
}

.educandoNew .barraMark p {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 162.4%;
  letter-spacing: -0.025em;
  color: #525E7A;
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 0;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .educandoNew .container {
    margin-bottom: -8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 8rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .educandoNew .container .leftText {
    width: 50%;
  }
  .educandoNew .container .leftText h1 {
    font-size: 2.6rem;
  }
  .educandoNew .container .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    max-width: 70%;
  }
  .educandoNew .container .rightImg {
    width: 45%;
  }
  .educandoNew .container .rightImg img {
    width: 100%;
    height: 100%;
    margin-left: 0rem;
  }
  .educandoNew .barraMark {
    padding-bottom: 2rem;
  }
  .educandoNew .barraMark .heading {
    background: #1BEFA9;
    padding: 2.5rem 0;
    margin-bottom: 2rem;
  }
  .educandoNew .barraMark .heading h1 {
    text-align: center;
    font-size: 2.25rem;
  }
  .educandoNew .barraMark p {
    font-weight: 500;
    font-size: 1.125rem;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .educandoNew .container {
    margin-bottom: -8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 6rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .educandoNew .container .leftText {
    width: 50%;
  }
  .educandoNew .container .leftText h1 {
    font-size: 2.6rem;
  }
  .educandoNew .container .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    max-width: 70%;
  }
  .educandoNew .container .rightImg {
    width: 45%;
  }
  .educandoNew .container .rightImg img {
    width: 100%;
    height: 100%;
    margin-left: 5rem;
  }
  .educandoNew .barraMark {
    padding-bottom: 2rem;
  }
  .educandoNew .barraMark .heading {
    background: #1BEFA9;
    padding: 2.5rem 0;
    margin-bottom: 2rem;
  }
  .educandoNew .barraMark .heading h1 {
    text-align: center;
    font-size: 2.25rem;
  }
  .educandoNew .barraMark p {
    font-weight: 500;
    font-size: 1.125rem;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .educandoNew .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 5rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .educandoNew .container .leftText {
    width: 50%;
  }
  .educandoNew .container .leftText h1 {
    font-size: 2.25rem;
  }
  .educandoNew .container .leftText p {
    margin-top: 1rem;
    font-size: 1rem;
    max-width: 90%;
  }
  .educandoNew .container .rightImg {
    width: 45%;
    margin-bottom: -8rem;
  }
  .educandoNew .container .rightImg img {
    width: 100%;
    height: 100%;
    margin-left: 0rem;
  }
  .educandoNew .barraMark {
    padding-bottom: 2rem;
  }
  .educandoNew .barraMark .heading {
    background: #1BEFA9;
    padding: 2.5rem 0;
    margin-bottom: 2rem;
  }
  .educandoNew .barraMark .heading h1 {
    text-align: center;
    font-size: 2.25rem;
  }
  .educandoNew .barraMark p {
    font-weight: 500;
    font-size: 1.125rem;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .educandoNew .container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-top: 2rem;
    padding-inline: 2rem;
  }
  .educandoNew .container .leftText {
    width: 50%;
  }
  .educandoNew .container .leftText h1 {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 2.3rem;
    line-height: 95.9%;
    color: #060F26;
  }
  .educandoNew .container .leftText p {
    max-width: 100%;
  }
  .educandoNew .container .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
  }
  .educandoNew .container .rightImg {
    width: 50%;
  }
  .educandoNew .barraMark {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .educandoNew .barraMark .heading {
    background: #1BEFA9;
    padding: 2.5rem 0;
    margin-bottom: 2rem;
  }
  .educandoNew .barraMark .heading h1 {
    text-align: center;
    font-size: 2.1rem;
  }
  .educandoNew .barraMark p {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 2rem;
  }
}

@media only screen and (min-width: 550px) and (max-width: 760px) {
  .educandoNew .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 2rem;
    padding-inline: 2rem;
  }
  .educandoNew .container .leftText {
    width: 100%;
  }
  .educandoNew .container .leftText h1 {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 2.3rem;
    line-height: 95.9%;
    color: #060F26;
  }
  .educandoNew .container .leftText p {
    max-width: 100%;
  }
  .educandoNew .container .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
  }
  .educandoNew .container .rightImg {
    display: none;
  }
  .educandoNew .barraMark {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .educandoNew .barraMark .heading {
    background: #1BEFA9;
    padding: 2.5rem 0;
    margin-bottom: 2rem;
  }
  .educandoNew .barraMark .heading h1 {
    text-align: center;
    font-size: 2.1rem;
  }
  .educandoNew .barraMark p {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 2rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 549px) {
  .educandoNew {
    margin-top: 0rem;
  }
  .educandoNew .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 2rem;
    padding-inline: 2rem;
  }
  .educandoNew .container .leftText {
    width: 100%;
  }
  .educandoNew .container .leftText h1 {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 95.9%;
    color: #060F26;
  }
  .educandoNew .container .leftText p {
    max-width: 100%;
  }
  .educandoNew .container .leftText a {
    margin-top: 1rem;
    background: #1BEFA9;
    padding: 0.75rem 2.75rem;
    border-radius: 14px;
    color: #060F26;
  }
  .educandoNew .container .rightImg {
    display: none;
    width: 100%;
  }
  .educandoNew .container .rightImg img {
    width: 100%;
    height: 100%;
  }
  .educandoNew .barraMark {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .educandoNew .barraMark .heading {
    background: #1BEFA9;
    padding: 2.5rem 0;
    margin-bottom: 2rem;
  }
  .educandoNew .barraMark .heading h1 {
    text-align: center;
    font-size: 2.1rem;
  }
  .educandoNew .barraMark p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 162.4%;
    letter-spacing: -0.025em;
    color: #525E7A;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 2rem;
  }
}

@media only screen and (max-width: 1600px) {
  .educando {
    display: none;
  }
}

@media only screen and (min-width: 1600px) {
  .educandoNew {
    display: none;
  }
}

.PontosChave {
  margin-top: -12rem;
  background-color: #060F26;
  min-height: 65vh;
  padding-inline: 12rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.PontosChave .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.PontosChave .container .leftHeading {
  width: 50%;
  text-align: center;
}

.PontosChave .container .leftHeading h1 {
  color: #1BEFA9;
  font-weight: 700;
  font-size: 8rem;
  line-height: 0.8;
}

.PontosChave .container .leftHeading h4 {
  font-size: 3rem;
  color: #1BEFA9;
  font-weight: 700;
  line-height: 1;
}

.PontosChave .container .rightPontos {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.PontosChave .container .rightPontos ul li {
  margin-top: 10px;
}

.PontosChave .container .rightPontos ul li::marker {
  color: #1BEFA9;
}

.PontosChave .container .rightPontos ul li p {
  font-weight: 500;
  font-size: 20px;
  color: #1BEFA9;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .PontosChave {
    margin-top: 0rem;
    background-color: #060F26;
    min-height: 65vh;
    padding-inline: 8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container .leftHeading {
    width: 50%;
    text-align: center;
  }
  .PontosChave .container .leftHeading h1 {
    color: #1BEFA9;
    font-weight: 700;
    font-size: 6.25rem;
    line-height: 0.8;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 3rem;
    color: #1BEFA9;
    font-weight: 700;
    line-height: 1;
  }
  .PontosChave .container .rightPontos {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li::marker {
    color: #1BEFA9;
  }
  .PontosChave .container .rightPontos ul li p {
    font-weight: 500;
    font-size: 1.15rem;
    color: #1BEFA9;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .PontosChave {
    margin-top: 0rem;
    padding-inline: 5rem;
  }
  .PontosChave .container .leftHeading {
    width: 50%;
  }
  .PontosChave .container .leftHeading h1 {
    font-size: 5rem;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 3rem;
  }
  .PontosChave .container .rightPontos {
    width: 50%;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li p {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .PontosChave {
    margin-top: 0rem;
    padding-inline: 5rem;
  }
  .PontosChave .container .leftHeading {
    width: 50%;
  }
  .PontosChave .container .leftHeading h1 {
    font-size: 5.625rem;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 3.75rem;
  }
  .PontosChave .container .rightPontos {
    width: 50%;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li p {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .PontosChave {
    margin-top: 2rem;
    background-color: #060F26;
    min-height: 65vh;
    padding-inline: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container .leftHeading {
    width: 50%;
    text-align: center;
  }
  .PontosChave .container .leftHeading h1 {
    color: #1BEFA9;
    font-weight: 700;
    font-size: 4rem;
    line-height: 0.8;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 2.2rem;
    color: #1BEFA9;
    font-weight: 700;
    line-height: 1;
  }
  .PontosChave .container .rightPontos {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li::marker {
    color: #1BEFA9;
  }
  .PontosChave .container .rightPontos ul li p {
    font-weight: 500;
    font-size: 1.125rem;
    color: #1BEFA9;
  }
}

@media only screen and (min-width: 669px) and (max-width: 768px) {
  .PontosChave {
    margin-top: 0rem;
    min-height: 65vh;
    padding-inline: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .PontosChave .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container .leftHeading {
    width: 100%;
    text-align: left;
    padding-top: 4rem;
  }
  .PontosChave .container .leftHeading h1 {
    color: #1BEFA9;
    font-weight: 700;
    font-size: 3.4rem;
    line-height: 0.8;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 2rem;
    color: #1BEFA9;
    font-weight: 700;
    line-height: 1;
  }
  .PontosChave .container .rightPontos {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 2rem;
    padding-bottom: 2rem;
    padding-inline: 2rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li::marker {
    color: #1BEFA9;
  }
  .PontosChave .container .rightPontos ul li p {
    font-weight: 500;
    font-size: 1.125rem;
    color: #1BEFA9;
  }
}

@media only screen and (min-width: 501px) and (max-width: 668px) {
  .PontosChave {
    margin-top: 0rem;
    min-height: 65vh;
    padding-inline: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .PontosChave .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container .leftHeading {
    width: 100%;
    text-align: left;
    padding-top: 4rem;
  }
  .PontosChave .container .leftHeading h1 {
    color: #1BEFA9;
    font-weight: 700;
    font-size: 3rem;
    line-height: 0.8;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 2rem;
    color: #1BEFA9;
    font-weight: 700;
    line-height: 1;
  }
  .PontosChave .container .rightPontos {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2rem;
    padding-bottom: 2rem;
    padding-inline: 2rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li::marker {
    color: #1BEFA9;
  }
  .PontosChave .container .rightPontos ul li p {
    font-weight: 500;
    font-size: 1.125rem;
    color: #1BEFA9;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .PontosChave {
    margin-top: 0rem;
    min-height: 65vh;
    padding-inline: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .PontosChave .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .PontosChave .container .leftHeading {
    width: 100%;
    text-align: left;
    padding-top: 4rem;
  }
  .PontosChave .container .leftHeading h1 {
    color: #1BEFA9;
    font-weight: 700;
    font-size: 3rem;
    line-height: 0.8;
  }
  .PontosChave .container .leftHeading h4 {
    font-size: 2rem;
    color: #1BEFA9;
    font-weight: 700;
    line-height: 1;
  }
  .PontosChave .container .rightPontos {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2rem;
    padding-bottom: 2rem;
    padding-inline: 2rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .PontosChave .container .rightPontos ul li {
    margin-top: 10px;
  }
  .PontosChave .container .rightPontos ul li::marker {
    color: #1BEFA9;
  }
  .PontosChave .container .rightPontos ul li p {
    font-weight: 500;
    font-size: 1.125rem;
    color: #1BEFA9;
  }
}

@media only screen and (max-width: 1600px) {
  .PontosChave {
    margin-top: 0;
  }
}

.VideoSection {
  height: 50vh;
  width: 100%;
  position: relative;
}

.VideoSection video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center 20%;
     object-position: center 20%;
  display: block;
}

@media only screen and (max-width: 600px) {
  .VideoSection {
    height: 30vh;
  }
  .VideoSection video {
    -o-object-position: unset;
       object-position: unset;
  }
}

.DiferentesPlanos {
  position: relative;
  padding: 8rem 20rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.DiferentesPlanos .plano {
  width: 100%;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  background-color: white;
}

.DiferentesPlanos .plano .heading {
  text-align: center;
  padding: 4rem 2rem;
}

.DiferentesPlanos .plano .heading h1 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
}

.DiferentesPlanos .plano .heading h1 b {
  font-weight: bold;
}

.DiferentesPlanos .plano .infoplano {
  background-color: #060F26;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  padding: 4rem 4rem 5rem;
}

.DiferentesPlanos .plano .infoplano ul li {
  color: #FFFFFF;
  font-weight: 400;
  padding-top: 0.2rem;
  font-size: 1.2rem;
}

.DiferentesPlanos::before {
  content: '';
  width: 100%;
  height: 175px;
  background-color: #1BEFA9;
  position: absolute;
  top: 40%;
  left: 0;
  z-index: -1;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .DiferentesPlanos {
    padding: 8rem 8rem;
  }
  .DiferentesPlanos .plano .heading {
    padding: 4rem 2rem;
  }
  .DiferentesPlanos .plano .heading h1 {
    font-size: 3rem;
  }
  .DiferentesPlanos .plano .infoplano {
    padding: 4rem 4rem 5rem;
  }
  .DiferentesPlanos .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .DiferentesPlanos {
    padding: 8rem 5rem;
  }
  .DiferentesPlanos .plano .heading {
    padding: 4rem 2rem;
  }
  .DiferentesPlanos .plano .heading h1 {
    font-size: 2.4rem;
  }
  .DiferentesPlanos .plano .infoplano {
    padding: 3rem 3rem 4rem;
  }
  .DiferentesPlanos .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .DiferentesPlanos {
    padding: 8rem 5rem;
  }
  .DiferentesPlanos .plano .heading {
    padding: 4rem 2rem;
  }
  .DiferentesPlanos .plano .heading h1 {
    font-size: 2.4rem;
  }
  .DiferentesPlanos .plano .infoplano {
    padding: 3rem 3rem 4rem;
  }
  .DiferentesPlanos .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 669px) and (max-width: 1023px) {
  .DiferentesPlanos {
    padding: 8rem 2rem;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .DiferentesPlanos .plano .heading {
    padding: 4rem 2rem;
  }
  .DiferentesPlanos .plano .heading h1 {
    font-size: 2rem;
  }
  .DiferentesPlanos .plano .infoplano {
    padding: 3rem 3rem 4rem;
  }
  .DiferentesPlanos .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 668px) {
  .DiferentesPlanos {
    position: relative;
    padding: 8rem 2rem 4rem;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    gap: 2rem;
  }
  .DiferentesPlanos .plano {
    width: 100%;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
    background-color: white;
  }
  .DiferentesPlanos .plano .heading {
    text-align: center;
    padding: 2rem 2rem;
  }
  .DiferentesPlanos .plano .heading h1 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1;
  }
  .DiferentesPlanos .plano .heading h1 b {
    font-weight: bold;
  }
  .DiferentesPlanos .plano .infoplano {
    background-color: #060F26;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
    padding: 2rem 4rem 4rem;
  }
  .DiferentesPlanos .plano .infoplano ul li {
    color: #FFFFFF;
    font-weight: 400;
    padding-top: 0.2rem;
    font-size: 1rem;
  }
  .DiferentesPlanos .plano.naoDisponivel {
    display: none;
  }
  .DiferentesPlanos::before {
    content: '';
    width: 100%;
    height: 150px;
    background-color: #1BEFA9;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;
  }
}

.contactForm {
  padding: 5rem 12rem 10rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contactForm .splitLeft {
  width: 50%;
  text-align: left;
}

.contactForm .splitLeft h4 {
  font-weight: bold;
  font-size: 4rem;
}

.contactForm .splitLeft p {
  font-weight: 400;
  font-size: 2rem;
  max-width: 80%;
}

.contactForm .splitRight {
  background-color: #E4E4E4;
  padding: 4rem 8rem;
  width: 50%;
}

.contactForm .splitRight form .form-group {
  margin-top: 40px;
}

.contactForm .splitRight form .form-group label {
  display: block;
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 5px;
}

.contactForm .splitRight form .form-group input {
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  -webkit-font-feature-settings: 'pnum' on, 'lnum' on;
          font-feature-settings: 'pnum' on, 'lnum' on;
}

.contactForm .splitRight form input[type="submit"], .contactForm .splitRight form a {
  margin-top: 4rem;
  display: inline-block;
  background-color: #1BEFA9;
  color: #060F26;
  padding: 10px 40px;
  border: none;
  border: 1px solid #1BEFA9;
  font-weight: bold;
  font-size: 1.025rem;
  border-radius: 55px;
  cursor: pointer;
  -webkit-transition: background-color 0.6s ease, -webkit-transform 0.6s ease;
  transition: background-color 0.6s ease, -webkit-transform 0.6s ease;
  transition: transform 0.6s ease, background-color 0.6s ease;
  transition: transform 0.6s ease, background-color 0.6s ease, -webkit-transform 0.6s ease;
}

.contactForm .splitRight form input[type="submit"]:hover, .contactForm .splitRight form a:hover {
  background-color: #FFFFFF;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .contactForm {
    padding: 5rem 8rem 2.5rem;
  }
  .contactForm .splitLeft {
    width: 40%;
  }
  .contactForm .splitLeft h4 {
    font-size: 3.6rem;
  }
  .contactForm .splitLeft p {
    font-size: 1.5rem;
    max-width: 80%;
  }
  .contactForm .splitRight {
    padding: 4rem 8rem;
    width: 60%;
  }
  .contactForm .splitRight form .form-group label {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .contactForm {
    padding: 5rem 5rem 2.5rem;
  }
  .contactForm .splitLeft {
    width: 40%;
  }
  .contactForm .splitLeft h4 {
    font-size: 3rem;
  }
  .contactForm .splitLeft p {
    font-size: 1.5rem;
    max-width: 80%;
  }
  .contactForm .splitRight {
    padding: 4rem 5rem;
    width: 60%;
  }
  .contactForm .splitRight form .form-group {
    margin-top: 30px;
  }
  .contactForm .splitRight form .form-group label {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .contactForm {
    padding: 5rem 5rem 2.5rem;
  }
  .contactForm .splitLeft {
    width: 40%;
  }
  .contactForm .splitLeft h4 {
    font-size: 3rem;
  }
  .contactForm .splitLeft p {
    font-size: 1.5rem;
    max-width: 80%;
  }
  .contactForm .splitRight {
    padding: 4rem 5rem;
    width: 60%;
  }
  .contactForm .splitRight form .form-group {
    margin-top: 30px;
  }
  .contactForm .splitRight form .form-group label {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 669px) and (max-width: 1023px) {
  .contactForm {
    padding: 5rem 2rem 2.5rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .contactForm .splitLeft {
    width: 100%;
  }
  .contactForm .splitLeft h4 {
    font-size: 3rem;
  }
  .contactForm .splitLeft p {
    font-size: 1.5rem;
    max-width: 80%;
  }
  .contactForm .splitRight {
    margin-top: 2rem;
    padding: 4rem 5rem;
    width: 100%;
  }
  .contactForm .splitRight form .form-group {
    margin-top: 30px;
  }
  .contactForm .splitRight form .form-group label {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 668px) {
  .contactForm {
    padding: 5rem 0;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .contactForm .splitLeft {
    width: 100%;
    padding-inline: 2rem;
  }
  .contactForm .splitLeft h4 {
    font-size: 3rem;
  }
  .contactForm .splitLeft p {
    font-size: 1.4rem;
    max-width: 100%;
  }
  .contactForm .splitRight {
    margin-top: 2rem;
    padding: 2rem;
    width: 100%;
  }
  .contactForm .splitRight form .form-group label {
    font-size: 1rem;
  }
}

.popupMenu {
  position: fixed;
  top: 0;
  -webkit-transform: translateX(-100vw);
          transform: translateX(-100vw);
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  height: 100vh;
  width: 100%;
  background-color: #000000;
}

.popupMenu .container {
  height: 100%;
}

.popupMenu .container ul {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 6rem;
}

.popupMenu .container ul li {
  padding-top: 2rem;
}

.popupMenu .container ul li a {
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
}

.popupMenu.active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  text-align: center;
}

.planoSingle {
  position: relative;
  padding: 8rem 20rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5rem;
}

.planoSingle .plano {
  width: 40%;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  background-color: white;
}

.planoSingle .plano .heading {
  text-align: center;
  padding: 4rem 2rem;
}

.planoSingle .plano .heading h1 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
}

.planoSingle .plano .heading h1 b {
  font-weight: bold;
}

.planoSingle .plano .infoplano {
  background-color: #060F26;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  padding: 4rem 4rem 5rem;
}

.planoSingle .plano .infoplano ul li {
  color: #FFFFFF;
  font-weight: 400;
  padding-top: 0.2rem;
  font-size: 1.2rem;
}

.planoSingle .preco {
  width: 35%;
}

.planoSingle .preco h1 {
  font-size: 3rem;
  font-weight: 700;
  -webkit-font-feature-settings: 'pnum' on, 'lnum' on;
          font-feature-settings: 'pnum' on, 'lnum' on;
  position: relative;
  color: #060F26;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.planoSingle .preco svg {
  width: 70px;
}

.planoSingle .vetorSeta {
  width: 25%;
  mix-blend-mode: multiply;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.planoSingle .absoluteBarra {
  background-color: #1BEFA9;
  height: 150px;
  width: 200vw;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .planoSingle {
    padding: 8rem 8rem;
    gap: 3rem;
  }
  .planoSingle .plano {
    width: 40%;
  }
  .planoSingle .plano .heading {
    padding: 4rem 2rem;
  }
  .planoSingle .plano .heading h1 {
    font-size: 3rem;
  }
  .planoSingle .plano .infoplano {
    padding: 4rem 4rem 5rem;
  }
  .planoSingle .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1.2rem;
  }
  .planoSingle .preco {
    width: 35%;
  }
  .planoSingle .preco h1 {
    font-size: 3rem;
    gap: 10px;
  }
  .planoSingle .preco svg {
    width: 70px;
  }
  .planoSingle .vetorSeta {
    width: 25%;
  }
  .planoSingle .vetorSeta img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .planoSingle .absoluteBarra {
    height: 150px;
    width: 200vw;
  }
}

@media only screen and (min-width: 1261px) and (max-width: 1439px) {
  .planoSingle {
    padding: 8rem 5rem;
    gap: 2rem;
  }
  .planoSingle .plano {
    width: 35%;
  }
  .planoSingle .plano .heading {
    padding: 4rem 2rem;
  }
  .planoSingle .plano .heading h1 {
    font-size: 2.4rem;
  }
  .planoSingle .plano .infoplano {
    padding: 3rem 3rem 5rem;
  }
  .planoSingle .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
  .planoSingle .preco {
    width: 40%;
  }
  .planoSingle .preco h1 {
    font-size: 2.4rem;
    gap: 10px;
  }
  .planoSingle .preco svg {
    width: 60px;
  }
  .planoSingle .vetorSeta {
    width: 25%;
  }
  .planoSingle .vetorSeta img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .planoSingle .absoluteBarra {
    height: 150px;
    width: 200vw;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .planoSingle {
    padding: 8rem 5rem;
    gap: 2rem;
  }
  .planoSingle .plano {
    width: 35%;
  }
  .planoSingle .plano .heading {
    padding: 4rem 2rem;
  }
  .planoSingle .plano .heading h1 {
    font-size: 2.4rem;
  }
  .planoSingle .plano .infoplano {
    padding: 3rem 3rem 5rem;
  }
  .planoSingle .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
  .planoSingle .preco {
    width: 40%;
  }
  .planoSingle .preco h1 {
    font-size: 2.4rem;
    gap: 10px;
  }
  .planoSingle .preco svg {
    width: 60px;
  }
  .planoSingle .vetorSeta {
    width: 25%;
  }
  .planoSingle .vetorSeta img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .planoSingle .absoluteBarra {
    height: 150px;
    width: 200vw;
  }
}

@media only screen and (min-width: 669px) and (max-width: 1023px) {
  .planoSingle {
    padding: 8rem 2rem;
    gap: 2rem;
  }
  .planoSingle .plano {
    width: 50%;
  }
  .planoSingle .plano .heading {
    padding: 4rem 2rem;
  }
  .planoSingle .plano .heading h1 {
    font-size: 2rem;
  }
  .planoSingle .plano .infoplano {
    padding: 3rem 3rem 4rem;
  }
  .planoSingle .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
  .planoSingle .preco {
    width: 50%;
  }
  .planoSingle .preco h1 {
    font-size: 2rem;
    gap: 30px;
  }
  .planoSingle .preco svg {
    width: 80px;
  }
  .planoSingle .vetorSeta {
    display: none;
  }
}

@media only screen and (max-width: 668px) {
  .planoSingle {
    padding: 8rem 4rem;
    gap: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .planoSingle .plano {
    width: 100%;
  }
  .planoSingle .plano .heading {
    padding: 4rem 2rem;
  }
  .planoSingle .plano .heading h1 {
    font-size: 2.4rem;
  }
  .planoSingle .plano .infoplano {
    padding: 3rem 3rem 4rem;
  }
  .planoSingle .plano .infoplano ul li {
    padding-top: 0.2rem;
    font-size: 1rem;
  }
  .planoSingle .preco {
    width: 100%;
  }
  .planoSingle .preco h1 {
    font-size: 2.4rem;
    gap: 30px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .planoSingle .preco svg {
    display: none;
  }
  .planoSingle .vetorSeta {
    display: none;
  }
  .planoSingle .absoluteBarra {
    bottom: 0;
    top: unset;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 420px) {
  .planoSingle {
    padding: 8rem 2rem;
    gap: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
