.VideoSection{
    height: 50vh;
    width: 100%;
    position: relative;

    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 20%;
        display: block;

    }
}

@media only screen and (max-width:600px){
    .VideoSection{
        height: 30vh;

        video{
            object-position: unset;
        }
    }
}