.planoSingle{
    position: relative;
    padding: 8rem 20rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 5rem;



    .plano{
        width: 40%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;
        background-color: white;
        
        .heading{
            text-align: center;
            padding: 4rem 2rem;
            
            h1{
                font-size:3rem;
                font-weight: 400;
                line-height: 1;

                b{
                    font-weight: bold;
                }
            }
        }

        .infoplano{
            background-color: $azul;
            border-bottom-left-radius: 70px;
            border-bottom-right-radius: 70px;
            padding: 4rem 4rem 5rem;
            ul{
    
                li{
                    color: $branco;
                    font-weight: 400;
                    padding-top: 0.2rem;
                    font-size: 1.2rem;
                }
            }
        }
        
    }

    .preco{
        width:35%;

        h1{
            font-size: 3rem;
            font-weight: 700;
            font-feature-settings: 'pnum' on, 'lnum' on;
            position: relative;
            color: $titulos;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        svg{
            width: 70px;
        }
    }

    .vetorSeta{
        width: 25%;
        mix-blend-mode: multiply;
        display: flex;
    }

    .absoluteBarra{
        background-color: $verde;
        height: 150px;
        width: 200vw;
        position: absolute;
        top:50%;
        transform: translate(-50%,-50%);
        z-index: -1;
    }
}


@media only screen and (min-width:1440px) and (max-width:1600px){


    .planoSingle{
        padding: 8rem 8rem;
        gap: 3rem;
        
        .plano{
            width: 40%;

            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size:3rem;
                }
            }
    
            .infoplano{
                padding: 4rem 4rem 5rem;
                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1.2rem;
                    }
                }
            }
            
        }
    
        .preco{
            width:35%;
    
            h1{
                font-size: 3rem;
                gap: 10px;
            }
    
            svg{
                width: 70px;
            }
        }
    
        .vetorSeta{
            width: 25%;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    
        .absoluteBarra{
            height: 150px;
            width: 200vw;
        }
    }


}

@media only screen and (min-width:1261px) and (max-width:1439px){

    .planoSingle{
        padding: 8rem 5rem;
        gap: 2rem;
        
        .plano{
            width: 35%;

            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size:2.4rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 5rem;
                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
            
        }
    
        .preco{
            width:40%;
    
            h1{
                font-size: 2.4rem;
                gap: 10px;
            }
    
            svg{
                width: 60px;
            }
        }
    
        .vetorSeta{
            width: 25%;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    
        .absoluteBarra{
            height: 150px;
            width: 200vw;
        }
    }

}


@media only screen and (min-width:1024px) and (max-width:1260px){

    .planoSingle{
        padding: 8rem 5rem;
        gap: 2rem;
        
        .plano{
            width: 35%;

            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size:2.4rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 5rem;
                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
            
        }
    
        .preco{
            width:40%;
    
            h1{
                font-size: 2.4rem;
                gap: 10px;
            }
    
            svg{
                width: 60px;
            }
        }
    
        .vetorSeta{
            width: 25%;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    
        .absoluteBarra{
            height: 150px;
            width: 200vw;
        }
    }
}


@media only screen and (min-width:669px) and (max-width:1023px){

    .planoSingle{
        padding: 8rem 2rem;
        gap: 2rem;
        
        .plano{
            width: 50%;

            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size:2rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 4rem;
                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
            
        }
    
        .preco{
            width:50%;
    
            h1{
                font-size: 2rem;
                gap: 30px;
            }
    
            svg{
                width: 80px;
            }
        }
    
        .vetorSeta{
         display: none;
        }
    
      
    }

}

@media only screen and (max-width:668px){

    .planoSingle{
        padding: 8rem 4rem;
        gap: 2rem;
        flex-direction: column;

        .plano{
            width: 100%;

            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size:2.4rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 4rem;
                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
            
        }
    
        .preco{
            width:100%;
    
            h1{
                font-size: 2.4rem;
                gap: 30px;
                justify-content: center;
            }
    
            svg{
                display: none;
            }
        }
    
        .vetorSeta{
         display: none;
        }

        .absoluteBarra{
            bottom: 0;
            top: unset;
            transform: translate(0%,-50%);
        }
    
      
    }

}

@media only screen and (max-width:420px){
    .planoSingle{
        padding: 8rem 2rem;
        gap: 2rem;
        flex-direction: column;
    }
}