.popupMenu{
    position: fixed;
    top: 0;
    transform: translateX(-100vw);
    transition: transform 0.6s ease;

    height: 100vh;
    width: 100%;
    background-color: #000000;

    .container{
        height: 100%;

        ul{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: 6rem;

            li{
                padding-top: 2rem;
                a{
                    color: white;
                    font-size: 1.5rem;
                    font-weight: 400;
                }
            }
        }
    }
    
    &.active{
        transform: translateX(0);
        transition: transform 0.6s ease;
        text-align: center;

      
    }
}