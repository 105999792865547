.importanciaDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding-inline: 12rem;

    .leftText{
        width: 50%;
        max-width: 39%;

        h1{
            font-family: 'Raleway',sans-serif;
            font-weight: 400;
            font-size: 48px;
            line-height: 95.9%;
            color: #060F26;
        }

        p{
            margin-top: 1rem;
            font-family: 'Raleway',sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 162.4%;
            letter-spacing: -0.025em;
            color: #525E7A;
            max-width: 80%;
        }

        a{
            margin-top: 1rem;
            background: #1BEFA9;
            padding: 0.75rem 2.75rem;
            border-radius: 14px;
            color: #060F26;
            font-weight: bold;
            transition: background .6s ease , color .6s ease, transform 1s ease;

            &:hover{
                transform: scale(1.1);
                background-color: #41f8bb;
                color: #fff;
            }
        }
    }

    .rightImg{
        width: 50%;
        text-align: center;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 700px;
            max-width: 700px;
        }

    }
}

@media only screen and (min-width:1601px) and (max-width:1750px){
    .importanciaDiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 8rem;
    
        .leftText{
            width: 50%;
            max-width: 40%;
    
            h1{
                font-family: 'Raleway',sans-serif;
                font-weight: 400;
                font-size: 48px;
                line-height: 95.9%;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-family: 'Raleway',sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width: 50%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
        }
    }
}

@media only screen and (min-width:1440px) and (max-width:1600px){
    .importanciaDiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        padding-inline: 8rem;
    
        .leftText{
            width: 50%;
            max-width: 40%;
    
            h1{
                font-family: 'Raleway',sans-serif;
                font-weight: 400;
                font-size: 48px;
                line-height: 95.9%;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-family: 'Raleway',sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width: 50%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
        }
    }
}

@media only screen and (min-width:1261px) and (max-width:1439px){
    .importanciaDiv{

        padding-inline: $pInline5;
    
        .leftText{
            width: 50%;
            max-width: 40%;
    
            h1{
                font-size: 2.25rem;
            }
    
            p{
            }

        }
    
        .rightImg{
            width: 50%;
        }
    }
}

@media only screen and (min-width:1024px) and (max-width:1260px){

    .importanciaDiv{

        padding-inline: $pInline5;
    
        .leftText{
            width: 50%;
            max-width: 40%;
    
            h1{
                font-size: 2.25rem;
            }
    
            p{
            }

        }
    
        .rightImg{
            width: 50%;
        }
    }
}

@media only screen and (min-width:669px) and (max-width:1024px){
    .importanciaDiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        padding-inline: 2rem;
    
        .leftText{
            width: 50%;
            max-width: 50%;
    
            h1{
                font-family: 'Raleway',sans-serif;
                font-weight: 400;
                font-size: 2.5rem;
                line-height: 95.9%;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-family: 'Raleway',sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width: 50%;

            img{
                width: 100%;
                height: 100%;
            }
    
        }
    }
    
}

@media only screen and (min-width:669px) and (max-width:768px){
    .importanciaDiv{
        flex-direction: column;        
        padding-inline: 2rem;
    
        .leftText{
            width: 100%;
            max-width: unset;
    
            h1{
                font-size: 3em;
                line-height: 1;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-size: 1rem;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width:70%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (min-width:501px) and (max-width:668px){


    .importanciaDiv{
        flex-direction: column;        
        padding-inline: 2rem;
    
        .leftText{
            width: 100%;
            max-width: unset;
    
            h1{
                font-size: 2.5em;
                line-height: 1;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-size: 1rem;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width:70%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    

}

@media only screen and (min-width:360px) and (max-width:500px){


    .importanciaDiv{
        flex-direction: column;        
        padding-inline: 2rem;
    
        .leftText{
            width: 100%;
            max-width: unset;
    
            h1{
                font-size: 2.125rem;
                line-height: 1;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-size: 1rem;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    

}

@media only screen and (min-width:0px) and (max-width:359px){


    .importanciaDiv{
        flex-direction: column;        
        padding-inline: 2rem;
    
        .leftText{
            width: 100%;
            max-width: unset;
    
            h1{
                font-size: 2rem;
                line-height: 1;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-size: 1rem;
                color: #525E7A;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
                font-weight: bold;
                transition: background .6s ease , color .6s ease, transform 1s ease;
    
                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }
    
        .rightImg{
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    

}