.PontosChave{
    margin-top: -12rem;
    background-color: $azul;
    min-height: 65vh;
    padding-inline: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .container{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .leftHeading{
            width: 50%;
            text-align: center;
            h1{
                color: $verde;
                font-weight: 700;
                font-size: 8rem;
                line-height: 0.8;
            }
            h4{
                font-size: 3rem;
                color: $verde;
                font-weight: 700;
                line-height: 1;
            }
        }

        .rightPontos{
            width: 50%;
            display: flex;
            justify-content: center;

            ul{

                li{
                    margin-top: 10px;
                    &::marker{
                        color: $verde;
                    }

                    p{
                        font-weight: 500;
                        font-size: 20px;
                        color: $verde;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1440px) and (max-width:1600px){

    .PontosChave{
        margin-top: 0rem;
        background-color: #060F26;
        min-height: 65vh;
        padding-inline: 8rem;
        display: flex;
        align-items: center;
    
        .container{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
    
            .leftHeading{
                width: 50%;
                text-align: center;
                h1{
                    color: #1BEFA9;
                    font-weight: 700;
                    font-size: 6.25rem;
                    line-height: 0.8;
                }
                h4{
                    font-size: 3rem;
                    color: #1BEFA9;
                    font-weight: 700;
                    line-height: 1;
                }
            }
    
            .rightPontos{
                width: 50%;
                display: flex;
                justify-content: center;
    
                ul{
    
                    li{
                        margin-top: 10px;
                        &::marker{
                            color: #1BEFA9;
                        }
    
                        p{
                            font-weight: 500;
                            font-size: 1.15rem;
                            color: #1BEFA9;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:1261px) and (max-width:1439px){

    .PontosChave{
        margin-top: 0rem;
        padding-inline: $pInline5;

    
        .container{

            .leftHeading{
                width: 50%;
                h1{
                    font-size: 5rem;
                }
                h4{
                    font-size: 3rem;
                }
            }
    
            .rightPontos{
                width: 50%;

                ul{
    
                    li{
                        margin-top: 10px;
    
                        p{
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1024px) and (max-width:1260px){

    .PontosChave{
        margin-top: 0rem;
        padding-inline: $pInline5;

    
        .container{

            .leftHeading{
                width: 50%;
                h1{
                    font-size: 5.625rem;
                }
                h4{
                    font-size: 3.75rem;
                }
            }
    
            .rightPontos{
                width: 50%;

                ul{
    
                    li{
                        margin-top: 10px;
    
                        p{
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-width:769px) and (max-width:1024px){
    .PontosChave{
        margin-top: 2rem;
        background-color: #060F26;
        min-height: 65vh;
        padding-inline: 2rem;
        display: flex;
        align-items: center;
    
        .container{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
    
            .leftHeading{
                width: 50%;
                text-align: center;
                h1{
                    color: #1BEFA9;
                    font-weight: 700;
                    font-size: 4rem;
                    line-height: 0.8;
                }
                h4{
                    font-size: 2.2rem;
                    color: #1BEFA9;
                    font-weight: 700;
                    line-height: 1;
                }
            }
    
            .rightPontos{
                width: 50%;
                display: flex;
                justify-content: center;
    
                ul{
    
                    li{
                        margin-top: 10px;
                        &::marker{
                            color: #1BEFA9;
                        }
    
                        p{
                            font-weight: 500;
                            font-size: 1.125rem;
                            color: #1BEFA9;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:669px) and (max-width:768px){

    .PontosChave{
        margin-top: 0rem;
        min-height: 65vh;
        padding-inline: 2rem;
        flex-direction: column;
        justify-content: center;
    
        .container{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            .leftHeading{
                width: 100%;
                text-align: left;
                padding-top: 4rem;

                h1{
                    color: #1BEFA9;
                    font-weight: 700;
                    font-size: 3.4rem;
                    line-height: 0.8;
                }
                h4{
                    font-size: 2rem;
                    color: #1BEFA9;
                    font-weight: 700;
                    line-height: 1;
                }
            }
    
            .rightPontos{
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 2rem;
                padding-bottom: 2rem;
                padding-inline: 2rem;
                justify-content: center;
    
                ul{
    
                    li{
                        margin-top: 10px;
                        &::marker{
                            color: #1BEFA9;
                        }
    
                        p{
                            font-weight: 500;
                            font-size: 1.125rem;
                            color: #1BEFA9;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:501px) and (max-width:668px){

    .PontosChave{
        margin-top: 0rem;
        min-height: 65vh;
        padding-inline: 2rem;
        flex-direction: column;
    
        .container{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            .leftHeading{
                width: 100%;
                text-align: left;
                padding-top: 4rem;

                h1{
                    color: #1BEFA9;
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 0.8;
                }
                h4{
                    font-size: 2rem;
                    color: #1BEFA9;
                    font-weight: 700;
                    line-height: 1;
                }
            }
    
            .rightPontos{
                width: 100%;
                display: flex;
                margin-top: 2rem;
                padding-bottom: 2rem;
                padding-inline: 2rem;
                justify-content: center;
    
                ul{
    
                    li{
                        margin-top: 10px;
                        &::marker{
                            color: #1BEFA9;
                        }
    
                        p{
                            font-weight: 500;
                            font-size: 1.125rem;
                            color: #1BEFA9;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:0px) and (max-width:500px){

    .PontosChave{
        margin-top: 0rem;
        min-height: 65vh;
        padding-inline: 2rem;
        flex-direction: column;
    
        .container{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            .leftHeading{
                width: 100%;
                text-align: left;
                padding-top: 4rem;

                h1{
                    color: #1BEFA9;
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 0.8;
                }
                h4{
                    font-size: 2rem;
                    color: #1BEFA9;
                    font-weight: 700;
                    line-height: 1;
                }
            }
    
            .rightPontos{
                width: 100%;
                display: flex;
                margin-top: 2rem;
                padding-bottom: 2rem;
                padding-inline: 2rem;
                justify-content: center;
    
                ul{
    
                    li{
                        margin-top: 10px;
                        &::marker{
                            color: #1BEFA9;
                        }
    
                        p{
                            font-weight: 500;
                            font-size: 1.125rem;
                            color: #1BEFA9;
                        }
                    }
                }
            }
        }
    }

}


@media only screen and (max-width:1600px){
    .PontosChave{
        margin-top: 0;
    }
}