.educando{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding-inline: 12rem;

    .leftText{
        width: 55%;
        margin-top:-8rem;

        h1{
            font-family: 'Raleway';
            font-weight: 400;
            font-size: 48px;
            line-height: 95.9%;
            color: #060F26;
        }

        p{
            margin-top: 1rem;
            font-family: 'Raleway';
            font-weight: 500;
            font-size: 16px;
            line-height: 162.4%;
            letter-spacing: -0.025em;
            color: #525E7A;
            max-width: 70%;
        }

        a{
            margin-top: 1rem;
            background: #1BEFA9;
            padding: 0.75rem 2.75rem;
            border-radius: 14px;
            color: #060F26;
        }

        .newText{

            h1{
                margin-top: 3rem;
                margin-bottom: 2rem;
                font-weight: bold;
            }
        }
    }

    .rightImg{
        width: 45%;
        img{
            margin-left:-10rem;
        }
    }
}

.educandoNew{
    
    .container{
        display: flex;
        align-items: center;
        padding-inline: $pInline12;
        justify-content: space-between;

        .leftText{
            width: 50%;
    
            h1{
                font-family: 'Raleway';
                font-weight: 400;
                font-size: 48px;
                line-height: 95.9%;
                color: #060F26;
            }
    
            p{
                margin-top: 1rem;
                font-family: 'Raleway';
                font-weight: 500;
                font-size: 16px;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: $textos;
                max-width: 70%;
            }
    
            a{
                margin-top: 1rem;
                background: #1BEFA9;
                padding: 0.75rem 2.75rem;
                border-radius: 14px;
                color: #060F26;
            }
    
        }
    
        .rightImg{
            width: 45%;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }

    .barraMark{
        padding-top: 2rem;
        padding-bottom: 2rem;
        
        .heading{
            background: $verde;
            padding: 2.5rem 0;

            h1{
               text-align: center;
               font-size: 2.25rem;
            }
            
            margin-bottom: 2rem;
        }

        p{
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 162.4%;
            letter-spacing: -0.025em;
            color: $textos;
            max-width: 50%;
            margin: 0 auto;
            text-align: center;
            padding: 2rem 0;
        }
    }
}

@media only screen and (min-width:1440px) and (max-width:1600px){

    .educandoNew{
    
        .container{
            margin-bottom: -8rem;
            display: flex;
            align-items: center;
            padding-inline: $pInline8;
            justify-content: space-between;
    
            .leftText{
                width: 50%;
        
                h1{
                    font-size: 2.6rem;
                }
        
                p{
                    margin-top: 1rem;
                    font-size: 1rem;
                    max-width: 70%;
                }
            }
        
            .rightImg{
                width: 45%;

                img{
                    width: 100%;
                    height: 100%;
                    margin-left: 0rem;
                }
            }
        }
    
        .barraMark{
            padding-bottom: 2rem;
            
            .heading{
                background: $verde;
                padding: 2.5rem 0;
    
                h1{
                   text-align: center;
                   font-size: 2.25rem;
                }
                
                margin-bottom: 2rem;
            }
    
            p{
                font-weight: 500;
                font-size: 1.125rem;
                max-width: 50%;
                margin: 0 auto;
                text-align: center;
                padding: 2rem 0;
            }
        }
    }

}

@media only screen and (min-width:1261px) and (max-width:1439px){

    .educandoNew{
    
        .container{
            margin-bottom: -8rem;
            display: flex;
            align-items: center;
            padding-inline: $pInline6;
            justify-content: space-between;
    
            .leftText{
                width: 50%;
        
                h1{
                    font-size: 2.6rem;
                }
        
                p{
                    margin-top: 1rem;
                    font-size: 1rem;
                    max-width: 70%;
                }
            }
        
            .rightImg{
                width: 45%;

                img{
                    width: 100%;
                    height: 100%;
                    margin-left: 5rem;
                }
            }
        }
    
        .barraMark{
            padding-bottom: 2rem;
            
            .heading{
                background: $verde;
                padding: 2.5rem 0;
    
                h1{
                   text-align: center;
                   font-size: 2.25rem;
                }
                
                margin-bottom: 2rem;
            }
    
            p{
                font-weight: 500;
                font-size: 1.125rem;
                max-width: 50%;
                margin: 0 auto;
                text-align: center;
                padding: 2rem 0;
            }
        }
    }

}

@media only screen and (min-width:1024px) and (max-width:1260px){

    .educandoNew{
    
        .container{
            display: flex;
            align-items: center;
            padding-inline: $pInline5;
            justify-content: space-between;
    
            .leftText{
                width: 50%;
        
                h1{
                    font-size: 2.25rem;
                }
        
                p{
                    margin-top: 1rem;
                    font-size: 1rem;
                    max-width: 90%;
                }
            }
        
            .rightImg{
                width: 45%;
                margin-bottom: -8rem;

                img{
                    width: 100%;
                    height: 100%;
                    margin-left: 0rem;
                }
            }
        }
    
        .barraMark{
            padding-bottom: 2rem;
            
            .heading{
                background: $verde;
                padding: 2.5rem 0;
    
                h1{
                   text-align: center;
                   font-size: 2.25rem;
                }
                
                margin-bottom: 2rem;
            }
    
            p{
                font-weight: 500;
                font-size: 1.125rem;
                max-width: 50%;
                margin: 0 auto;
                text-align: center;
                padding: 2rem 0;
            }
        }
    }

}

@media only screen and (min-width:769px) and (max-width:1024px){

    .educandoNew{
    
        .container{
            flex-direction: row;
            padding-top: 2rem;
            padding-inline: $pInline2;
    
            .leftText{
                width: 50%;
        
                h1{
                    font-family: 'Raleway';
                    font-weight: 400;
                    font-size: 2.3rem;
                    line-height: 95.9%;
                    color: #060F26;
                }
        
                p{
                    max-width: 100%;
                }
        
                a{
                    margin-top: 1rem;
                    background: #1BEFA9;
                    padding: 0.75rem 2.75rem;
                    border-radius: 14px;
                    color: #060F26;
                }
        
            }
        
            .rightImg{
                width: 50%;
            }
        }
    
        .barraMark{
            padding-top: 2rem;
            padding-bottom: 2rem;
            
            .heading{
                background: $verde;
                padding: 2.5rem 0;
    
                h1{
                   text-align: center;
                   font-size: 2.1rem;
                }
                
                margin-bottom: 2rem;
            }
    
            p{
                max-width: 80%;
                margin: 0 auto;
                text-align: center;
                padding: 2rem 2rem;
            }
        }
    }


}

@media only screen and (min-width:550px) and (max-width:760px){

    .educandoNew{
    
        .container{
            flex-direction: column;
            padding-top: 2rem;
            padding-inline: $pInline2;
    
            .leftText{
                width: 100%;
        
                h1{
                    font-family: 'Raleway';
                    font-weight: 400;
                    font-size: 2.3rem;
                    line-height: 95.9%;
                    color: #060F26;
                }
        
                p{
                    max-width: 100%;
                }
        
                a{
                    margin-top: 1rem;
                    background: #1BEFA9;
                    padding: 0.75rem 2.75rem;
                    border-radius: 14px;
                    color: #060F26;
                }
        
            }
        
            .rightImg{
                display: none;
            }
        }
    
        .barraMark{
            padding-top: 2rem;
            padding-bottom: 2rem;
            
            .heading{
                background: $verde;
                padding: 2.5rem 0;
    
                h1{
                   text-align: center;
                   font-size: 2.1rem;
                }
                
                margin-bottom: 2rem;
            }
    
            p{
                max-width: 80%;
                margin: 0 auto;
                text-align: center;
                padding: 2rem 2rem;
            }
        }
    }


}

@media only screen and (min-width:0px) and (max-width:549px){

    .educandoNew{
        margin-top: 0rem;
        
        .container{
            flex-direction: column;
            padding-top: 2rem;
            padding-inline: $pInline2;
    
            .leftText{
                width: 100%;
        
                h1{
                    font-family: 'Raleway';
                    font-weight: 400;
                    font-size: 2.1rem;
                    line-height: 95.9%;
                    color: #060F26;
                }
        
                p{
                    max-width: 100%;
                }
        
                a{
                    margin-top: 1rem;
                    background: #1BEFA9;
                    padding: 0.75rem 2.75rem;
                    border-radius: 14px;
                    color: #060F26;
                }
        
            }
        
            .rightImg{
                display: none;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    
        .barraMark{
            padding-top: 2rem;
            padding-bottom: 2rem;
            
            .heading{
                background: $verde;
                padding: 2.5rem 0;
    
                h1{
                   text-align: center;
                   font-size: 2.1rem;
                }
                
                margin-bottom: 2rem;
            }
    
            p{
                
                font-weight: 500;
                font-size: 1rem;
                line-height: 162.4%;
                letter-spacing: -0.025em;
                color: $textos;
                max-width: 100%;
                margin: 0 auto;
                text-align: center;
                padding: 2rem 2rem;
            }
        }
    }


}



@media only screen and (max-width:1600px){
    .educando{
        display: none;
    }
}

@media only screen and (min-width:1600px){
    .educandoNew{
        display: none;
    }
}