.contactForm{

    padding: 5rem 12rem 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .splitLeft{
        width: 50%;
        text-align: left;
        h4{
            font-weight: bold;
            font-size: 4rem;
        }
        p{
            font-weight: 400;
            font-size: 2rem;
            max-width: 80%;
        }
    }


    .splitRight{
        background-color: #E4E4E4;
        padding: 4rem 8rem;
        width: 50%;
        form{


            .form-group{
                margin-top: 40px;
                label{
                    display: block;
                    font-weight: 600;
                    font-size:1.2rem;
                    padding-bottom: 5px;
                }

                input{
                    outline: none;
                    border: none;
                    padding: 10px;
                    width: 100%;
                    font-size: 16px;
                    font-feature-settings: 'pnum' on, 'lnum' on;

                    &:invalid{
                        border: 1px solid red;
                    }
                }
            }

            input[type="submit"],a{
                margin-top: 4rem;
                display: inline-block;
                background-color: $verde;
                color: $azul;
                padding: 10px 40px;
                border: none;
                border: 1px solid $verde;
                font-weight: bold;
                font-size: 1.025rem;
                border-radius: 55px;
                cursor: pointer;
                transition: transform 0.6s ease, background-color 0.6s ease;
              
                &:hover{
                    background-color: $branco;
                    transform: scale(1.1);
                }
            }
        }
    }
}


@media only screen and (min-width:1440px) and (max-width:1600px){

    .contactForm{

        padding: 5rem 8rem 2.5rem;

    
        .splitLeft{
            width: 40%;

            h4{
                font-size: 3.6rem;
            }
            p{
                font-size: 1.5rem;
                max-width: 80%;
            }
        }
    
    
        .splitRight{
            padding: 4rem 8rem;
            width: 60%;

            form{

                .form-group{

                    label{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1261px) and (max-width:1439px){
   
    .contactForm{

        padding: 5rem 5rem 2.5rem;

    
        .splitLeft{
            width: 40%;

            h4{
                font-size: 3rem;
            }
            p{
                font-size: 1.5rem;
                max-width: 80%;
            }
        }
    
    
        .splitRight{
            padding: 4rem 5rem;
            width: 60%;

            form{
                
                .form-group{
                    margin-top:30px;

                    label{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:1024px) and (max-width:1260px){
  
    .contactForm{

        padding: 5rem 5rem 2.5rem;

    
        .splitLeft{
            width: 40%;

            h4{
                font-size: 3rem;
            }
            p{
                font-size: 1.5rem;
                max-width: 80%;
            }
        }
    
    
        .splitRight{
            padding: 4rem 5rem;
            width: 60%;

            form{
                
                .form-group{
                    margin-top:30px;

                    label{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:669px) and (max-width:1023px){

    .contactForm{

        padding: 5rem 2rem 2.5rem;
        flex-direction: column;
    
        .splitLeft{
            width: 100%;

            h4{
                font-size: 3rem;
            }
            p{
                font-size: 1.5rem;
                max-width: 80%;
            }
        }
    
    
        .splitRight{
            margin-top: 2rem;
            padding: 4rem 5rem;
            width: 100%;

            form{
                
                .form-group{
                    margin-top:30px;

                    label{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width:668px){
   
    .contactForm{

        padding: 5rem 0;
        align-items: flex-start;
        flex-direction: column;
    
    
        .splitLeft{
            width: 100%;
            padding-inline: $pInline2;
            h4{
                font-size: 3rem;
            }
            p{
                font-size: 1.4rem;
                max-width: 100%;
            }
        }
    
    
        .splitRight{
            margin-top: 2rem;
            padding: 2rem;
            width: 100%;

            form{
                .form-group{
                    label{
                        font-size:1rem;
                    }
                }
            }
        }
    }
}