.headerMenu{
    position: absolute;
    top: 40px;
    width: 100%;
    height: 100px;
    transition: background .6s ease;

    &.bg-black{
        transition: background .6s ease;
        background-color: #000;
    }

    .container{
        display: flex;
        padding-inline: 12rem;
        align-items: center;
        justify-content: space-between;

  
        .logo{}

        .menu{
            ul{
                display: flex;
                gap: 25px;
                align-items: center;
                list-style-type: none;

                li{
                    a{
                        font-size: 1.125rem;
                        color: white;
                    }
                }
            }
        }

        .mobileMenu{
            display: none;
        }
    }
}

@media only screen and (min-width:1601px) and (max-width:1750px){

    .headerMenu{    
        .container{
            padding-inline: 8rem;
        }
    }
}

@media only screen and (min-width:1440px) and (max-width:1600px){

    .headerMenu{    
        .container{
            padding-inline: 8rem;
        }
    }
}

@media only screen and (min-width:1260px) and (max-width:1439px){

    .headerMenu{    
        .container{
            padding-inline: 8rem;
        }
    }
}

@media only screen and (min-width:1024px) and (max-width:1260px){
    .headerMenu{

    
        .container{
            padding-inline: $pInline5;
    
            .menu{

                ul{
                    gap: 20px;
        
                    li{
                        a{
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}



@media only screen and (max-width:1023px){
    .headerMenu{
        position: fixed;
        background-color: transparent;
        top: 0;
        width: 100%;
        z-index: 9999;
        height: 70px;

        .container{
            display: flex;
            align-items: center;
            padding-inline: 2rem;
            align-items: center;
            justify-content: space-between;
            height: 100%;
      
        .logo{
            img{
                height: 50px;
                width: 100%;
            }
        }
    
        .menu{
            display: none;
        }


        .mobileMenu{
            display: block;

            .mobileMenuIcon{

                .bar1,.bar2,.bar3{
                    width: 35px;
                    height: 3px;
                    background-color: #ffffff;
                    margin: 6px 0;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    border-radius: 22px;
                }
                
                .bar2{
                    width: 25px;
                    margin: auto 0 auto auto;
                }
                  
                

                    &.active{

                        .bar1 {
                            -webkit-transform: rotate(-45deg) translate(-9px, 3px);
                            transform: rotate(-45deg) translate(-9px, 3px);
                        }

                        .bar2 {
                            opacity: 0;
                        }

                        .bar3 {
                            -webkit-transform: rotate(45deg) translate(-8px, -5px);
                            transform: rotate(45deg) translate(-8px, -5px);
                        }

                    }
                }
            }
        }
    }
}