.DiferentesPlanos{
    position: relative;
    padding: 8rem 20rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;

    .plano{
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;
        background-color: white;
        
        .heading{
            text-align: center;
            padding: 4rem 2rem;
            
            h1{
                font-size:3rem;
                font-weight: 400;
                line-height: 1;

                b{
                    font-weight: bold;
                }
            }
        }

        .infoplano{
            background-color: $azul;
            border-bottom-left-radius: 70px;
            border-bottom-right-radius: 70px;
            padding: 4rem 4rem 5rem;
            ul{
    
                li{
                    color: $branco;
                    font-weight: 400;
                    padding-top: 0.2rem;
                    font-size: 1.2rem;
                }
            }
        }


        &.naoDisponivel{
            
            
        }
        
    }




    &::before{
        content: '';
        width: 100%;
        height: 175px;
        background-color:$verde;
        position: absolute;
        top: 40%;
        left: 0;
        z-index: -1;
    }

}


@media only screen and (min-width:1440px) and (max-width:1600px){

    .DiferentesPlanos{
        padding: 8rem 8rem;
    
        .plano{
            
            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size: 3rem;
                }
            }
    
            .infoplano{
                padding: 4rem 4rem 5rem;

                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1261px) and (max-width:1439px){

    .DiferentesPlanos{
        padding: 8rem 5rem;
    
        .plano{
            
            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size: 2.4rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 4rem;

                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1024px) and (max-width:1260px){

    .DiferentesPlanos{
        padding: 8rem 5rem;
    
        .plano{
            
            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size: 2.4rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 4rem;

                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width:669px) and (max-width:1023px){


    .DiferentesPlanos{
        padding: 8rem 2rem;
        grid-template-columns: 1fr 1fr;
        .plano{
            
            .heading{
                padding: 4rem 2rem;
                
                h1{
                    font-size: 2rem;
                }
            }
    
            .infoplano{
                padding: 3rem 3rem 4rem;

                ul{
        
                    li{
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

}



@media only screen and (max-width:668px){
    .DiferentesPlanos{
        position: relative;
        padding: 8rem 2rem 4rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    
        .plano{
            width: 100%;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-bottom-left-radius: 70px;
            border-bottom-right-radius: 70px;
            background-color: white;
    
            .heading{
                text-align: center;
                padding: 2rem 2rem;
                
                h1{
                    font-size:3rem;
                    font-weight: 400;
                    line-height: 1;
    
                    b{
                        font-weight: bold;
                    }
                }
            }
    
            .infoplano{
                background-color: $azul;
                border-bottom-left-radius: 70px;
                border-bottom-right-radius: 70px;
                padding: 2rem 4rem 4rem;
                ul{
        
                    li{
                        color: $branco;
                        font-weight: 400;
                        padding-top: 0.2rem;
                        font-size: 1rem;
                    }
                }
            }
    
    
            &.naoDisponivel{
                display: none;
            }
        }
    
    
    
    
        &::before{
            content: '';
            width: 100%;
            height: 150px;
            background-color:$verde;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: -1;
        }
    
    }
}