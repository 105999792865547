*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway',sans-serif;

    &:before,&::after{
        box-sizing: border-box;
    }

    scroll-behavior: smooth;
    scroll-padding-top:150px; 
}

@import './utility/colors';
@import './utility/variables';

body{
    overflow-x: hidden;
}

html{
    font-size:16px;
}

.dnone{
    display: none;
}

a{
    text-decoration: none;
    display: inline-block;
}

ul{
    padding: none;
    
}

mark{
    background-color: #1BEFA9;
    padding: 10px;
}

.halo_path {
    animation: move_halo 2s infinite;
    animation-direction: alternate-reverse;
}

@keyframes move_halo {
    0% {
        transform: translate(0, 5px);
    }
    100% {
        transform: translate(0, -10px);
    }
}

@import '../../Components/Header/headerMenu';
@import '../../Components/Hero/hero';
@import '../../Components/Importancia/importancia';
@import '../../Components/Importancia/avisarPais';
@import '../../Components/Importancia/formacao';
@import '../../Components/Importancia/educando';
@import '../../Components/PontosChave/pontosChave';
@import '../../Components/VideoSec/videoSection';

@import '../../Components/PricingTables/pricingtable';
@import '../../Components/ContactForm/contactform';
@import '../../Components/MobileMenu/MobilePop';
@import '../../Components/PricingTables/singletable';