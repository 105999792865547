.avisarPais{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 12rem;
    margin-top: -4rem;
    
    .leftText{
        width: 55%;

        h1{
            font-family: 'Raleway';
            font-weight: 400;
            font-size: 48px;
            line-height: 95.9%;
            color: $titulos;
        }

        p{
            margin-top: 1rem;
            font-family: 'Raleway';
            font-weight: 500;
            font-size: 16px;
            line-height: 162.4%;
            letter-spacing: -0.025em;
            color: $textos;
            max-width: 60%;
        }

        a{
            margin-top: 1rem;
            background: $verde;
            padding: 0.75rem 2.75rem;
            border-radius: 14px;
            color: $titulos;
        }


    }

    .rightImg{
        width: 45%;
        
        img{
            height: 100%;
            width: 100%;
            max-height: 500px;
            max-width: 500px;
        }
    }
}

@media only screen and (min-width:1440px) and (max-width:1600px){

    .avisarPais{

        padding-inline: $pInline8;
        margin-top: 4rem;
        
        .leftText{
            width: 50%;
    
            h1{
                font-size: 2.6rem;
            }
    
            p{
                max-width: 90%;
            }
    
        }
    
        .rightImg{
            width: 50%;
            
            img{
                width: 80%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}


@media only screen and (min-width:1261px) and (max-width:1439px){


    .avisarPais{
        padding-inline: $pInline5;
        margin-top: 4rem;
        
        .leftText{
            width: 50%;
    
            h1{
                font-size: 2.25rem;
            }
    
            p{
                max-width: 100%;
            }

        }
    
        .rightImg{
            width: 50%;
        }
    }
}


@media only screen and (min-width:1024px) and (max-width:1260px){


    .avisarPais{
        padding-inline: $pInline5;
        margin-top: 4rem;
        
        .leftText{
            width: 50%;
    
            h1{
                font-size: 2.25rem;
            }
    
            p{
                max-width: 100%;
            }

        }
    
        .rightImg{
            width: 50%;
        }
    }
}


@media only screen and (min-width:769px) and (max-width:1024px){
    .avisarPais{

        padding-inline: $pInline2;
        margin-top:4rem;
        gap: 2rem;
        
        .leftText{
            width: 50%;
    
            h1{
                font-size: 2.5rem;
            }
    
            p{
                max-width: 100%;
            }
    
     
    

        }
    
        .rightImg{
            width: 50%;
            
            img{
                width: 100%;
                height: 100%;
            }
            svg{
                height: 500px !important;
                width: 500px !important;
            }
        }
    }
}

@media only screen and (min-width:669px) and (max-width:768px){

    .avisarPais{
        flex-direction: column;
       
        padding-inline: $pInline2;
        margin-top: 2rem;
        margin-bottom: 2rem;
        
        .leftText{
            width: 100%;
    
            h1{
                font-size: 3rem;
            }
    
            p{
                max-width: 100%;
            }
        }
    
        .rightImg{
            width:70%;
            padding-top: 2rem;
            padding-bottom: 2rem;
            order:2;
            
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media only screen and (min-width:501px) and (max-width:668px){

    .avisarPais{
        flex-direction: column;
       
        padding-inline: $pInline2;
        margin-top: 2rem;
        margin-bottom: 2rem;
        
        .leftText{
            width: 100%;
    
            h1{
                font-weight: 400;
                font-size: 2.125rem;
            }
    
            p{

                max-width: 100%;
            }
        }
    
        .rightImg{
            width:70%;
            padding-top: 2rem;
            padding-bottom: 2rem;
            order:2;
            
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

}

@media only screen and (min-width:0px) and (max-width:500px){

    .avisarPais{
        flex-direction: column;
       
        padding-inline: $pInline2;
        margin-top: 2rem;
        margin-bottom: 2rem;
        
        .leftText{
            width: 100%;
    
            h1{
                font-size: 2.1rem;
            }
    
            p{
                max-width: 100%;
            }
        }
    
        .rightImg{
            width: 100%;
            padding-top: 2rem;
            padding-bottom: 2rem;
            text-align: center;
            order:2;
            
            img{
                object-fit: cover;
                width: 80%;
                height: 100%;
            }
        }
    }

}