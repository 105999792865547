.heroSection{
    min-height: 100vh;    
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: hidden;

    
    #fundoAbs{
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 12rem;
        width: 100%;
        
        .hero{
            width: 40%;

            h1{
                font-weight: 700;
                font-size: 4.5rem;
                line-height: 1;
                letter-spacing: -0.025em;
                max-width: 80%;
                color: #FFFFFF;
            }

            a{
                margin-top: 1.5rem;
                background-color: white;
                border-radius: 14px;
                padding: 0.8rem 2rem;
                font-weight: bold;
                color: #000;
                font-size: 1.125rem;

                transition: background .6s ease , color .6s ease, transform .6s ease;

                &:hover{
                    transform: scale(1.1);
                    background-color: #41f8bb;
                    color: #fff;
                }
            }
        }

        .imageContainer{
            width: 60%;

            img{
                object-fit: cover;
                height: 100%;
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width:1601px) and (max-width:1750px){
    .heroSection{
        min-height: 100vh;    
        background: url(../../srcFiles/img/hero/shapeFundo.svg) no-repeat;
        background-position: top;
        background-size: cover;
        display: flex;
        align-items: center;
    
        .container{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 8rem;
            width: 100%;
            margin-top: 6rem;
    
            
            .hero{
                width: 40%;
    
                h1{
                    font-weight: 700;
                    font-size: 4rem;
                    line-height: 1;
                    letter-spacing: -0.025em;
                    color: #FFFFFF;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 60%;
    
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    } 
}

@media only screen and (min-width:1440px) and (max-width:1600px){
    .heroSection{
        min-height: 100vh;    
        background: unset;
        background-position: top;
        background-size: cover;
        display: flex;
        align-items: center;
    
        .container{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 8rem;
            width: 100%;
            margin-top: 4rem;
    
            
            .hero{
                width: 40%;
    
                h1{
                    font-weight: 700;
                    font-size: 4rem;
                    line-height: 1;
                    letter-spacing: -0.025em;
                    color: #FFFFFF;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 60%;
    
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    } 
}

@media only screen and (min-width:1261px) and (max-width:1440px){
    .heroSection{
      
        #fundoAbs{
            position: absolute;
            top: 0;
            width: 120%;
            z-index: -1;
        }

        .container{
            padding-inline: $pInline6;
            width: 100%;
            margin-top: 0rem;
    
            
            .hero{
                width: 40%;
    
                h1{
                    font-size: 3.4rem;
                }

            }
    
            .imageContainer{
                width: 60%;
    
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    } 
}

@media only screen and (min-width:1024px) and (max-width:1260px){
    .heroSection{
      
        #fundoAbs{
            position: absolute;
            top: 0;
            width: 110%;
            object-position: top;
            object-fit: cover;
            z-index: -1;
        }

        .container{
            padding-inline: $pInline5;
            width: 100%;
            margin-top: 0rem;
    
            
            .hero{
                width: 40%;
    
                h1{
                    font-size: 3rem;
                }

            }
    
            .imageContainer{
                width: 60%;
    
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    } 
}

@media only screen and (min-width:769px) and (max-width:1023px){


    .heroSection{
        background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
        background-position: top;
        background-size: cover;
      
        display: flex;
        align-items: center;
    
        .container{
            display: flex;
            align-items: center;
            align-items: flex-start;
            padding-inline: 2rem;

            width: 100%;
            margin-top: 0rem;
    
            
            .hero{
                width: 60%;
    
                h1{
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 1;
                    letter-spacing: -0.025em;
                    color: #FFFFFF;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 60%;
    
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    


}

@media only screen and (min-width:669px) and (max-width:768px){


    
    .heroSection{
        background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
        padding-top: 80px;
        flex-direction: column;
        min-height: unset;
        padding-bottom: 4rem;
        background-position: top;
        background-size: cover;


        .container{
            flex-direction: column;
            padding-top: 4rem;
            padding-bottom: 4rem;
            display: flex;
            padding-inline: 2rem;
            width: 100%;
            gap: 1rem;
            margin-top: 0rem;
    
            
            .hero{
                width: 100%;
    
                h1{
                    font-size: 3.6rem;
                    line-height: 1.1;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 70%;
                margin-top: 0rem;
                padding-top: 2rem;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    


}

@media only screen and (min-width:591px) and (max-width:668px){


    .heroSection{
        background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
        padding-top: 80px;
        flex-direction: column;
        min-height: unset;
        padding-bottom: 4rem;
        background-position: top;
        background-size: cover;


        .container{
            flex-direction: column;
            padding-top: 4rem;
            padding-bottom: 4rem;
            display: flex;
            padding-inline: 2rem;
            width: 100%;
            gap: 1rem;
            margin-top: 0rem;
    
            
            .hero{
                width: 100%;
    
                h1{
                    font-size: 4rem;
                    line-height: 1.1;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 100%;
                margin-top: 0rem;
                padding-top: 2rem;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    


}

@media only screen and (min-width:501px) and (max-width:590px){


    .heroSection{
        background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
        padding-top: 80px;
        flex-direction: column;
        min-height: unset;
        padding-bottom: 4rem;
        background-position: top;
        background-size: cover;


        .container{
            flex-direction: column;
            padding-top: 4rem;
            padding-bottom: 4rem;
            display: flex;
            padding-inline: 2rem;
            width: 100%;
            gap: 1rem;
            margin-top: 0rem;
    
            
            .hero{
                width: 100%;
    
                h1{
                    font-size: 3.6rem;
                    line-height: 1.1;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 100%;
                margin-top: 0rem;
                padding-top: 2rem;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    


}

@media only screen and (min-width:360px) and (max-width:500px){


    .heroSection{
        background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
        padding-top: 60px;
        flex-direction: column;
        min-height: unset;
        padding-bottom: 4rem;
        background-position: top;
        background-size: cover;


        .container{
            flex-direction: column;
            padding-top: 4rem;
            padding-bottom: 4rem;
            display: flex;
            padding-inline: 2rem;
            width: 100%;
            gap: 1rem;
            margin-top: 0rem;
    
            
            .hero{
                width: 100%;
    
                h1{
                    font-size: 2.6rem;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 100%;
                margin-top: 0rem;
                padding-top: 2rem;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    


}

@media only screen and (min-width:0px) and (max-width:359px){


    .heroSection{
        background: url(../../srcFiles/img/hero/shapeMob.svg) no-repeat;
        padding-top: 60px;
        flex-direction: column;
        min-height: unset;
        padding-bottom: 4rem;
        background-position: top;
        background-size: cover;


        .container{
            flex-direction: column;
            padding-top: 4rem;
            padding-bottom: 4rem;
            display: flex;
            padding-inline: 2rem;
            width: 100%;
            gap: 1rem;
            margin-top: 0rem;
    
            
            .hero{
                width: 100%;
    
                h1{
                    font-size: 2.2rem;
                }
    
                a{
                    margin-top: 1.5rem;
                    background-color: white;
                    border-radius: 14px;
                    padding: 0.8rem 2rem;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.125rem;
                }
            }
    
            .imageContainer{
                width: 100%;
                margin-top: 0rem;
                padding-top: 2rem;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    


}


